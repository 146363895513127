(function() {
    'use strict';

    angular.module('aerosApp')
        .controller('ProjectSorImportController', ProjectSorImportController);

    ProjectSorImportController.$inject = ['$scope', '$state'];

    function ProjectSorImportController($scope, $state) {
        $scope.projectId = $state.params.id;
    }
})();
